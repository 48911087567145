@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  padding: pxToRem(4) pxToRem(6);
  border-radius: pxToRem(8);
  background-color: var(--bac-tie2);
}

.feeBreakdown {
  background-color: var(--for-neu-tie3);
}
