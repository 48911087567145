@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  padding-bottom: pxToRem(18);
  padding-top: pxToRem(8);
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
}

.header {
  margin: 0 0 pxToRem(24);
  position: sticky;
  padding-bottom: pxToRem(8);
  padding-top: pxToRem(8);
  top: pxToRem(64);
  background-color: $color-neutral-000;
  z-index: $zIndex-headerBelow;
  :global(body.scroll-down) & {
    top: 0;
  }
}

.subHeader {
  padding-top: pxToRem(24);
  padding-bottom: pxToRem(24);
}

.subSection:first-child {
  .subHeader {
    padding-top: 0;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: pxToRem(20) pxToRem(16);
}

.row {
  display: flex;
  align-items: center;
  gap: pxToRem(8);
  min-width: 0;
  max-width: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

@include md {
  .root {
    padding-bottom: pxToRem(32);
  }
  .header {
    :global(body.scroll-down) & {
      top: pxToRem(64);
    }
  }
}

@include mdp {
  .container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxToRem(24);
  }
}

@include xl {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}
