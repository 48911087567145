@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.content {
  display: flex;
  flex-direction: column;
  gap: pxToRem(20);
}

.text {
  color: rgba(21, 13, 44, 0.7);
}

.makesList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}

.makeColumn {
  margin: 0 0 0 pxToRem(-25);
}

.makes {
  text-transform: capitalize;
}
