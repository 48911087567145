@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: grid;
  row-gap: pxToRem(8);
  margin-top: pxToRem(24);
}

.icon {
  width: pxToRem(80);
  height: pxToRem(80);
}

.textContainer {
  display: grid;
  row-gap: pxToRem(8);
}

.title {
  @include fontSize(20, 28);
  font-weight: 800;
  color: var(--tex-def-tie1);
}

.description {
  @include fontSize(15, 20);
  font-weight: 400;
  color: var(--tex-def-tie2);
}

@include sm {
  .root {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: pxToRem(16);
  }

  .icon {
    width: pxToRem(72);
    height: pxToRem(72);
    min-width: pxToRem(72);
  }
}
