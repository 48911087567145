@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

$minOpacity: #{0.01}; // If opacity 0, Lighthouse LCP results are negatively impacted
$appearSpeed: 500ms;
$appearDelay: 250;
$baseHeaderSize: 48;
$backgroundColour: #6632d6;

@keyframes wordAppear {
  from {
    opacity: $minOpacity;
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes wordReveal {
  0% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
  99% {
    opacity: 1;
    transform: translate3d(0, 10%, 0);
  }
  100% {
    opacity: $minOpacity;
    transform: translate3d(0, 10%, 0);
  }
}

@keyframes carAppear {
  from {
    opacity: $minOpacity;
    transform: translate3d(-0.15em, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes contentAppear {
  from {
    opacity: $minOpacity;
    transform: translate3d(0, #{pxToRem(24)}, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes lineAppear {
  from {
    opacity: $minOpacity;
    transform: scaleX(0.01);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
@keyframes fadeIn {
  from {
    opacity: $minOpacity;
  }
  to {
    opacity: 1;
  }
}

.root {
  --section-paddingTop: #{pxToRem(56)};
  --section-paddingBottom: #{pxToRem(40)};
  @include fullWidthBackgroundColor($backgroundColour);
  @include sectionHorizontalSpacing();
  padding-top: calc(var(--section-paddingTop) + $headerHeight);
  padding-bottom: var(--section-paddingBottom);
  color: var(--tex-inv-tie1);
}

.header {
  font-size: 12.1vw;
  line-height: 1em;
  font-family: var(--font-display);
  font-weight: 800;
}

.word,
.word::after,
.car,
.subtitleWord,
.content,
.footer::before,
.footer :global(.valuePropItem) {
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-duration: $appearSpeed;
}

.word,
.car {
  @for $i from 1 through 3 {
    &:nth-of-type(#{$i}) {
      &,
      &::after {
        animation-delay: calc(#{$i} * #{$appearDelay}ms);
      }
    }
  }
}

.word,
.subtitleWord {
  animation-name: wordAppear;
  opacity: 0;
  transform: translate3d(0, 30%, 0);
  animation-fill-mode: forwards;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 100%;
    left: auto;
    width: 100%;
    height: 100%;
    background: $backgroundColour;
    animation-name: wordReveal;
    animation-fill-mode: forwards;
  }
}

.car {
  display: inline-block;
  vertical-align: text-bottom;
  height: auto;
  position: relative;
  opacity: 0;
  animation-name: carAppear;
  animation-fill-mode: forwards;
}

.car1 {
  width: #{(115 / $baseHeaderSize)}em;
  margin: #{-(2 / $baseHeaderSize)}em #{-(18 / $baseHeaderSize)}em;
  top: #{-(6 / $baseHeaderSize)}em;
}
.car2 {
  width: #{(175 / $baseHeaderSize)}em;
  margin: #{-(18 / $baseHeaderSize)}em #{-(38 / $baseHeaderSize)}em;
  top: #{-(8 / $baseHeaderSize)}em;
}
.car3 {
  width: #{(175 / $baseHeaderSize)}em;
  margin: #{-(17 / $baseHeaderSize)}em #{-(33 / $baseHeaderSize)}em;
  top: 0;
}

.subtitle {
  font-family: var(--font-default);
  font-size: #{(14 / $baseHeaderSize)}em;
  line-height: 1.1;
  font-weight: normal;
  display: inline-block;
}

.subtitleWord {
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      &,
      &::after {
        animation-delay: calc(#{$i * $appearDelay / 4}ms + #{3 * $appearDelay}ms);
      }
    }
  }
}

.content {
  margin-top: pxToRem(40);
  animation-name: contentAppear;
  animation-delay: calc(4 * #{$appearDelay}ms);
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translate3d(0, #{pxToRem(24)}, 0);
  position: relative;
}

.footer {
  --footer-spacing: #{pxToRem(20)};
  margin-top: var(--footer-spacing);
  padding-top: var(--footer-spacing);
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: thin solid rgba(255, 255, 255, 0.15);
    animation-name: lineAppear;
    opacity: 0;
    transform: scaleX(0.01);
    animation-delay: calc(5 * #{$appearDelay}ms);
    animation-fill-mode: forwards;
    animation-duration: #{$appearDelay * 3}ms;
    transform-origin: left;
  }

  :global {
    .valueProps {
      padding-top: 0;
      border-top: none;
      box-sizing: border-box;
    }

    .valuePropItem {
      :local {
        animation-name: contentAppear;
      }
      animation-fill-mode: forwards;
      opacity: 0;
      transform: translate3d(0, #{pxToRem(24)}, 0);
      position: relative;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: calc((4 + (#{$i} * 0.5)) * #{$appearDelay}ms);
        }
      }
    }

    .valuePropImage {
      background: #0b051d66;
      padding: pxToRem(6);
      box-sizing: border-box;
      border-radius: var(--cor_rad-ful);
    }
  }
}

@include sm {
  .header {
    font-size: 12.5vw;
  }

  .footer {
    --footer-spacing: #{pxToRem(32)};
  }
}

@include md {
  .root {
    --section-paddingTop: #{pxToRem(64)};
    --section-paddingBottom: #{pxToRem(56)};
    display: grid;
    grid-template:
      '🚗 🔎' auto
      '🔥 🔥' auto / 6fr 4fr;
    column-gap: pxToRem(32);
  }

  .header {
    grid-area: 🚗;
    align-self: center;
    font-size: min(7vw, #{pxToRem(106)});
  }

  .content {
    grid-area: 🔎;
    margin-top: 0;
    align-self: center;
    animation-delay: calc(6.5 * #{$appearDelay}ms);
  }

  .footer {
    grid-area: 🔥;

    :global {
      .valueProps {
        padding-left: pxToRem(40);
        padding-right: pxToRem(40);
      }

      .valuePropItem {
        max-width: pxToRem(308);
      }
    }
  }
}

@include lg {
  .header {
    font-size: pxToRem(106);
  }
}

// Reduced motion animations
@media (prefers-reduced-motion) {
  .word,
  .subtitleWord,
  .car,
  .content,
  .footer::before,
  .footer :global(.valuePropItem):local {
    animation-name: fadeIn;
  }

  .word,
  .subtitleWord {
    &::after {
      content: none;
    }
  }
}
