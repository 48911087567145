@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  box-shadow:
    0 pxToRem(2) pxToRem(8) 0 rgba(0, 0, 0, 0.16),
    0 0 0 0 rgba(0, 0, 0, 0.04);
  background-color: var(--for-neu-tie3);
  border-radius: var(--cor_rad-md);
  min-height: pxToRem(382);

  :global {
    .adyen-checkout__loading-input__form {
      padding: 0;
    }

    .MuiFormHelperText-root.Mui-error {
      margin-bottom: pxToRem(24);
    }

    .adyen-checkout__input,
    .adyen-checkout__input:hover,
    .adyen-checkout__input.adyen-checkout__input--error {
      box-shadow: none;
      border: none;
    }
  }
  &:global + .MuiFormHelperText-root.Mui-error {
    margin-top: pxToRem(8);
  }

  &.error {
    border-color: $color-sentimental-error-600;
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: pxToRem(420);

  .circularProgress {
    width: pxToRem(58);
    height: pxToRem(58);
  }

  span {
    padding-top: pxToRem(12);
    @include fontSize(15, 20);
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: pxToRem(24);
}

.sslLabel {
  gap: pxToRem(8);
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(14);
  align-items: center;
  justify-content: center;

  svg {
    width: pxToRem(28);
    height: pxToRem(28);
  }
}

.paymentLabelRow {
  display: flex;
  width: 100%;
  gap: 10px;
}

.paymentLabel {
  font-weight: bold;
  font-size: pxToRem(17);
  flex-grow: 1;
}

.cardPayment {
  padding-bottom: pxToRem(20);
  width: 100%;
}

.generalErrorText {
  padding-bottom: pxToRem(24);
  color: var(--tex-err-tie1);
}

@include sm {
  .footer {
    flex-direction: row;

    button {
      width: pxToRem(360);
    }
  }
}
